








































































































































































import { Vue, Component } from "vue-property-decorator";

import { Input, Button, Table, TableColumn } from "element-ui";
import pagination from "@/components/UIComponents/Pagination.vue";
import ListTable from "@/views/registrations/ownership/listTable.vue";
import JsonExcel from "vue-json-excel";
import {
  MetaModule as metaStore,
  DriverModule as driverStore,
  OwnershipModule as ownerStore,
} from "@/store/modules";

import {
  OwnershipPaginatedResult,
  OwnershipSearchResult,
} from "@/store/models/registration/registration";

import { AdminRouter } from "@/utils/routePathContsant";
import helper from "@/utils/helpers";
import { getApi } from "@/store/api/api";
import { Action } from "@/store/actionType";

@Component({
  components: {
    Input,
    Button,
    Table,
    TableColumn,
    pagination,
    ListTable,
  },
})
export default class DutyOfficeClass extends Vue {
  ownerDetailPath = AdminRouter.VehicleOwnerDetails;
  vehiclePath = AdminRouter.VehicleApproval;
  palikas: any[] = [];
  qOwner: any[] = [];
  ownerList: any[] = [];
  // newOwnerList: any[] = [];
  ownerListByApproval: OwnershipPaginatedResult = {} as OwnershipPaginatedResult;
  // pendingOwnerList: any[] = [];
  // reappliedOwnerList: any[] = [];
  districtName: any = [];
  disname: string = "";
  filter: OwnershipSearchResult = {
    profile__approval_status: "",
    page: null,
    page_size: null,
    name: "",
  };
  tab: string = "New";

  async handleClick(tab: any, event: any) {
    this.tab = tab.name;
    this.filter.profile__approval_status = this.tab;
    this.filter.page = 1;
    await ownerStore.loadAllOwner(this.filter);
    this.ownerListByApproval = ownerStore.paginatedOwnershipList;
  }

  get AdminRouter() {
    return AdminRouter;
  }

  get routePath() {
    return AdminRouter;
  }
  get districts() {
    return metaStore.districtL;
  }
  get ownerCount() {
    return ownerStore.ownerCount;
  }
  async created() {
    metaStore.loadDis();
    ownerStore.getVehicleOwnerCount();
    this.filter.profile__approval_status = "New";
    this.filter.page = 1;
    await ownerStore.loadAllOwner(this.filter);
    this.ownerListByApproval = ownerStore.paginatedOwnershipList;
  }

  async handlePageChange(val: number) {
    this.filter.profile__approval_status = this.tab;
    this.filter.page = val;
    await ownerStore.loadAllOwner(this.filter);
    this.ownerListByApproval = ownerStore.paginatedOwnershipList;
  }

  edit(id: any) {
    this.$router.push(AdminRouter.VehicleOwnerDetails + "?id=" + id);
  }
  async filterPalika() {
    if (this.filter.profile__district) {
      this.palikas = await metaStore.loadMuncipality(
        this.filter.profile__district
      );
    }
  }
  async filterOwner() {
    await ownerStore.loadAllOwner(this.filter);
    this.ownerListByApproval = ownerStore.paginatedOwnershipList;
  }
  clearFilter() {
    this.filter = {
      page: 1,
      profile__approval_status: this.filter.profile__approval_status,
      page_size: this.filter.page_size,
      name: "",
    };
    this.filterOwner();
  }
  submit(e: any) {
    ownerStore.createOwnership();
  }
  async downloadExcel(e: any) {
    const action = Action.ownercsv;
    const sheet_path = await getApi(action);
    const link = document.createElement("a");
    link.setAttribute("href", sheet_path.excelSheetPath);
    link.click();
  }
}
