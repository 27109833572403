


























































































import { Component, Prop, Vue } from "vue-property-decorator";

import {
  OwnershipPaginatedResult,
  OwnershipSearchResult,
} from "@/store/models/registration/registration";
import { AdminRouter } from "@/utils/routePathContsant";
import helpers from "@/utils/helpers";
@Component
export default class OwnershipListTable extends Vue {
  @Prop({ required: true })
  paginatedData!: OwnershipPaginatedResult;

  get AdminRouter() {
    return AdminRouter;
  }

  momentDate(date: any) {
    return helpers.toMomentFormat(date);
  }

  handlePageChange(val: any) {
    this.$emit("handlePageChange", val);
  }
}
